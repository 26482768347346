<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <DiscountTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Старт</td>
                                    <td>Финиш</td>
                                    <td>Активен</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in schedules" :key="item.id">
                                    <td>{{ scheduleFormated(item.open_weekday, item.open_hours, item.open_minutes) }}</td>
                                    <td>{{ scheduleFormated(item.close_weekday, item.close_hours, item.close_minutes) }}</td>
                                    <td>
                                        <span :class="`true-false-label ${getBadge(item.enabled)}`">
                                            &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
                                        </span>
                                    </td>
                                    <td align="right">
                                        <v-icon color="primary" @click="edit(item)">mdi-pencil</v-icon>
                                    </td>
                                    <!-- <td>
                                        <v-icon color="primary" @click="remove(item)">mdi-trash-can</v-icon>
                                    </td> -->
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Промежуток
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <h3>С</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="editSchedule.open_weekday"
                                    label="День недели"
                                    :items="weekdays"
                                    item-text="title"
                                    item-value="value"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editSchedule.open_hours"
                                    outlined
                                    label="Часы"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editSchedule.open_minutes"
                                    outlined
                                    label="Минуты"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>По</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="editSchedule.close_weekday"
                                    label="День недели"
                                    :items="weekdays"
                                    item-text="title"
                                    item-value="value"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editSchedule.close_hours"
                                    outlined
                                    label="Часы"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editSchedule.close_minutes"
                                    outlined
                                    label="Минуты"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editSchedule.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editSchedule.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import DiscountTabLinks from '../../components/discounts/DiscountTabLinks';
    import { scheduleFormated } from '../../helpers';

    export default {
        name: 'DiscountSchedules',

        components: {
            DiscountTabLinks,
        },

        data() {
            return {
                scheduleFormated,
                editModal: false,
                editSchedule: {},
                weekdays: [
                    { value: 1, title: 'Понедельник' },
                    { value: 2, title: 'Вторник' },
                    { value: 3, title: 'Среда' },
                    { value: 4, title: 'Четверг' },
                    { value: 5, title: 'Пятница' },
                    { value: 6, title: 'Суббота' },
                    { value: 0, title: 'Воскресенье' },
                ]
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('discounts', {
                entity: state => state.entity
            }),
            ...mapState('discountSchedules', {
                schedules: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('discounts/get', {id});
                await store.dispatch('discountSchedules/fetch', {discount_id: id});
            }
        },

        methods: {
            create() {
                this.editSchedule = {
                    discount_id: this.$route.params.id,
                    open_weekday: 0,
                    open_hours: 0,
                    open_minutes: 0,
                    close_weekday: 0,
                    close_hours: 0,
                    close_minutes: 0,
                    enabled: true,
                    sort: 0
                };
                this.editModal = true;
            },
            edit(item) {
                this.editSchedule = JSON.parse(JSON.stringify(item));
                this.editModal = true;
            },
            async save() {
                try {
                    store.commit('discountSchedules/SET_ENTITY', this.editSchedule);
                    await store.dispatch('discountSchedules/save', {discount_id: this.$route.params.id});
                    await store.dispatch('discountSchedules/fetch', {discount_id: this.$route.params.id});
                    this.editModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                try {
                    await store.dispatch('discountSchedules/delete', {discount_id: this.$route.params.id, id: this.editSchedule.id});
                    await store.dispatch('discountSchedules/fetch', {discount_id: this.$route.params.id});
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/discounts/${id}/detail`);
                } else {
                    this.$router.push('/discounts');
                }
            },
        },
    };
</script>